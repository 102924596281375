import React from "react";
import desktopImage from "../assets/banner/web-bnr.jpg";
import mobileImage from "../assets/banner/mob-bnr.jpg";
import "./HeroSection.css";

function HeroSection() {
    return (
        <div className="w-full md:mt-[1.8rem]">
            <picture>
                <source
                    srcSet={desktopImage}
                    media="(min-width: 769px)"
                    type="image/webp"
                />
                <source
                    srcSet={mobileImage}
                    media="(max-width: 768px)"
                    type="image/webp"
                />
                <img
                    src={mobileImage}
                    alt="Banner"
                    className="banner-image"
                    loading="lazy" 
                    width="1200"
                    height="600"
                />
            </picture>
        </div>
    );
}

export default HeroSection;

